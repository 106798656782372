import React from "react";
import uniqueId from "lodash/uniqueId";

export const useAnimation = (id?: string) => {
  const [_id] = React.useState(id || uniqueId("image-features-grid"));
  const anchor = `#${_id}`;

  const animate = (animation: string, delay: number = 0, offset?: number) => {
    return {
      "data-aos": animation,
      "data-aos-anchor": anchor,
      "data-aos-delay": delay,
      "data-aos-offset": offset,
    };
  };

  return {
    id: _id,
    anchor,
    animate,
  };
};