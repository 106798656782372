import React, { FunctionComponent } from "react";

type HeroTitleProps = {
  className?: string;
  dark?: boolean;
};

const HeroTitle: FunctionComponent<HeroTitleProps> = ({
  children,
  className,
  dark = false,
}) => {
  return (
    <h1
      className={`${className} max-w-prose text-5xl sm:text-5xl md:text-7xl tracking-tight font-extrabold ${
        dark ? "text-white" : "text-gray-900"
      }`}
    >
      {children}
    </h1>
  );
};

export default HeroTitle;
