import React, { FunctionComponent } from "react";

type LeadTextProps = {
  center?: boolean;
  className?: string;
  dark?: boolean;
};

const LeadText: FunctionComponent<LeadTextProps> = ({
  children,
  center = false,
  className,
  dark = false,
}) => {
  return (
    <p
      className={`${
        dark ? "text-gray-300" : "text-gray-600"
      } text-lg leading-snug md:text-xl ${
        center ? "text-center" : ""
      } ${className}`}
    >
      {children}
    </p>
  );
};

export default LeadText;
