import React, { FunctionComponent } from "react";

import BodyText from "./BodyText";
import Title from "./Title";

type KeywordProps = {
  title: string;
  description?: string | React.ReactNode;
  dark?: boolean;
  center?: boolean;
  mobileCenter?: boolean;
  noMargin?: boolean;
};

const Keyword: FunctionComponent<KeywordProps> = ({
  title,
  description,
  center = false,
  mobileCenter = false,
  dark = false,
  noMargin = false,
}) => {
  return (
    <div
      className={
        center ? "text-center" : mobileCenter ? `text-center md:text-left` : ""
      }
    >
      <Title dark={dark} type="h4">
        {title}
      </Title>
      <hr
        className={`w-2/12 ${
          center ? "mx-auto" : mobileCenter ? "mx-auto md:mx-0" : ""
        } my-2 border-primary`}
      />
      {description && (
        <BodyText
          justify={false}
          dark={dark}
          className={`leading-snug ${center ? `text-justify` : ""}`}
          mb={noMargin ? 0 : undefined}
        >
          {description}
        </BodyText>
      )}
    </div>
  );
};

export default Keyword;
