import React, { FunctionComponent } from "react";
import { v4 as uuid } from "uuid";

export const Pattern: FunctionComponent<{
  className: string;
  type: "dot" | "square";
}> = ({ className, type }) => {
  const id = uuid();

  return (
    <svg className={className}>
      <defs>
        {type === "dot" ? (
          <DotPattern id={id}></DotPattern>
        ) : (
          <SquarePattern id={id}></SquarePattern>
        )}
      </defs>
      <rect width="100%" height="100%" fill={`url(#${id})`}></rect>
    </svg>
  );
};

const DotPattern: FunctionComponent<{ id: string }> = ({ id }) => {
  return (
    <pattern
      id={id}
      x="0"
      y="0"
      width="20"
      height="20"
      patternUnits="userSpaceOnUse"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="dots" fill="currentcolor">
          <circle id="Oval-377-Copy-9" cx="3" cy="3" r="3"></circle>
        </g>
      </g>
    </pattern>
  );
};

const SquarePattern: FunctionComponent<{ id: string }> = ({ id }) => {
  return (
    <pattern
      id={id}
      x="0"
      y="0"
      width="40"
      height="40"
      patternUnits="userSpaceOnUse"
    >
      <path fill="currentColor" d="M6 18h12V6H6v12zM4 4h16v16H4V4z"></path>
    </pattern>
  );
};
